import * as React from "react";
import { Box, Flex } from "rebass";
import PropTypes from "prop-types";
import { Waypoint } from "react-waypoint";
import Slider from "../Slider";
import { H3, H4, P } from "../Typography";
import Image from "../Image";
import { useScroll } from "../../hooks/scroll";

const Solution = ({ images: solutionImages, title, text, whatWeDid }) => {
  const [isVisible, handleChangePosition] = useScroll();
  return (
    <>
      <Waypoint bottomOffset="20%" onPositionChange={handleChangePosition} />
      <Flex
        flexDirection={["column", null, "row", null]}
        css={`
          opacity: ${isVisible ? 1 : 0};
          transform: ${isVisible ? "translateY(0px)" : "translateY(70px)"};
          transition: all 0.4s ease-out;
        `}
      >
        <Box
          px={[4, "115px", 11, 15]}
          py={[6, 11, 11, 15]}
          backgroundColor="white"
          css={`
            min-width: 70% !important;
            position: relative;
          `}
        >
          <P
            fontSize={["100px", "150px", null, null]}
            color="#eef2f4"
            fontWeight="bold"
            css={`
              line-height: 100px !important;
              position: absolute;
              top: 80px;
              left: 80px;
              @media (max-width: 1919px) {
                top: 50px;
                left: 50px;
              }
              @media (max-width: 767px) {
                top: 20px;
                left: 20px;
                line-height: 70px !important;
              }
            `}
          >
            01
          </P>
          <Box
            width={["100%", null, "60%", null]}
            css={{ position: "relative" }}
          >
            <H3 mb={3}>{title}</H3>
            <P mb={4}>{text}</P>
            <H4 mb={2}>{whatWeDid.title}</H4>
            <Box
              as="ul"
              css={`
                list-style-type: disc;
                padding-left: 17px;
              `}
            >
              {whatWeDid.list.map(item => (
                <P key={item} as="li">
                  {item}
                </P>
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          margin="auto"
          css={`
            min-width: 50% !important;
            transform: translateX(-40%);
            @media (max-width: 1279px) {
              min-width: calc(100% - 7%);
              max-width: calc(100% - 7%);
              transform: translateX(0);
              transform: translateY(-60px);
            }
            @media (max-width: 779px) {
              min-width: calc(100% - 12%);
              max-width: calc(100% - 12%);
              transform: translateX(0);
              transform: translateY(-30px);
            }
          `}
        >
          <Slider>
            {solutionImages.map(({ childImageSharp: { id, fluid } }) => (
              <Image key={id} fluid={fluid} />
            ))}
          </Slider>
        </Box>
      </Flex>
    </>
  );
};

Solution.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  whatWeDid: PropTypes.shape().isRequired
};

export default Solution;
