import React from "react";
import * as PropTypes from "prop-types";
import { Box } from "rebass";
import Image from "../Image";
import PlayIcon from "../../assets/icons/play.svg";

const VideoLink = ({ fluid, css, ...props }) => (
  <Box
    {...props}
    css={`
      position: relative;
      cursor: pointer;
      overflow: hidden;
      @media (min-width: 1280px) {
        :hover {
          #image {
            transform: scale(1.1);
          }
          #playIcon {
            transform: scale(1.1);
          }
        }
      }
      ${css}
    `}
  >
    <Box id="image" css={{ transition: "all 0.2s ease-in" }}>
      <Image id="image" fluid={fluid} />
    </Box>
    <Box
      id="playIcon"
      css={`
        width: 74px;
        height: 74px;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        margin: auto !important;
        transition: all 0.2s ease-in;
        @media (max-width: 1279px) {
          width: 64px;
          height: 64px;
        }
        @media (max-width: 767px) {
          width: 52px;
          height: 52px;
        }
      `}
    >
      <PlayIcon
        style={{
          width: "100%",
          height: "100%"
        }}
      />
    </Box>
  </Box>
);

VideoLink.propTypes = {
  fluid: PropTypes.shape().isRequired,
  css: PropTypes.shape()
};

VideoLink.defaultProps = {
  css: {}
};
export default VideoLink;
