import * as React from "react";
import { Waypoint } from "react-waypoint";
import { Box, Flex } from "rebass";
import { H3, P } from "../Typography";
import Link from "../Link";
import { useScroll } from "../../hooks/scroll";

const FindingCreatives = () => {
  const [isVisible, handleChangePosition] = useScroll();
  return (
    <>
      <Waypoint bottomOffset="20%" onPositionChange={handleChangePosition} />
      <Flex flexDirection={["column", null, "row", null]}>
        <Box
          px={[4, "115px", 11, 15]}
          py={[6, 11, 11, 15]}
          backgroundColor="white"
          css={`
            max-width: 56.6%;
            position: relative;
            @media (max-width: 1919px) {
              max-width: 69%;
            }
            @media (max-width: 1279px) {
              max-width: 100%;
            }
            opacity: ${isVisible ? 1 : 0};
            transform: ${isVisible ? "translateY(0px)" : "translateY(70px)"};
            transition: all 0.4s ease-out;
          `}
        >
          <P
            fontSize={["100px", "150px", null, null]}
            color="#eef2f4"
            fontWeight="bold"
            css={`
              line-height: 100px !important;
              position: absolute;
              top: 80px;
              left: 80px;
              @media (max-width: 1919px) {
                top: 50px;
                left: 50px;
              }
              @media (max-width: 767px) {
                top: 20px;
                left: 20px;
                line-height: 70px !important;
              }
            `}
          >
            03
          </P>
          <Box width="100%" css={{ position: "relative" }}>
            <H3 mb={3}>
              Finding The Best Creatives And Launching The UA Campaigns
            </H3>
            <P>
              After completing the testing, our User Acquisition team selected
              the best-performing creative videos and launched a scalable
              campaign that showed great results and helped the app efficiently
              reach the right people. Now let’s discuss your project! Drop us a
              notе on 
              <Link
                href="mailto:dashmediapro@gmail.com"
                color="#0A87BE"
                mr="3px"
                css={{ display: "inline" }}
              >
                dashmediapro@gmail.com
              </Link>
              and we will gladly help you out!
            </P>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default FindingCreatives;
