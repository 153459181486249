import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import { Box } from "rebass";
import LeftArrow from "../../assets/icons/leftArrow.svg";
import RightArrow from "../../assets/icons/rightArrow.svg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ArrowWrapper = ({ children, onClick, arrowStyle }) => (
  <Box
    onClick={onClick}
    css={`
      font-size: 0;
      line-height: 0;
      position: absolute;
      top: 50%;
      display: block;
      width: 20px;
      height: 20px;
      padding: 0;
      transform: translate(0, -50%);
      cursor: pointer;
      color: transparent;
      border: none;
      outline: none;
      background: transparent;
      width: 12px !important;
      height: 60px !important;
      &:hover {
        content: "";
      }
      @media (max-width: 767px) {
        width: 6px !important;
        height: 31.5px !important;
      }
      ${{ ...arrowStyle }}
    `}
  >
    {children}
  </Box>
);

const SliderComponent = ({ children }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: (
      <ArrowWrapper arrowStyle={{ left: -25 }}>
        <LeftArrow
          css={`
            width: 12px !important;
            height: 60px !important;
            @media (max-width: 767px) {
              width: 6px !important;
              height: 31.5px !important;
            }
          `}
        />
      </ArrowWrapper>
    ),
    nextArrow: (
      <ArrowWrapper arrowStyle={{ right: -25 }}>
        <RightArrow
          css={`
            width: 12px !important;
            height: 60px !important;
            @media (max-width: 767px) {
              width: 6px !important;
              height: 31.5px !important;
            }
          `}
        />
      </ArrowWrapper>
    )
  };
  return <Slider {...settings}>{children}</Slider>;
};

ArrowWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  arrowStyle: PropTypes.shape()
};

ArrowWrapper.defaultProps = {
  onClick: () => {},
  arrowStyle: {}
};

SliderComponent.propTypes = {
  children: PropTypes.node.isRequired
};

export default SliderComponent;
