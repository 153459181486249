import * as React from "react";
import { Box, Flex } from "rebass";
import PropTypes from "prop-types";
import { Waypoint } from "react-waypoint";
import { BrowserView } from "react-device-detect";
import { H3, H4, P } from "../Typography";
import VideoLink from "../VideoLink";
import Slider from "../Slider";
import { useScroll } from "../../hooks/scroll";

const MakingVideos = ({ title, text, whatWeDid, videos, openVideo }) => {
  const [
    {
      url: img1Url,
      image: {
        childImageSharp: { fluid: img1Fluid }
      }
    },
    {
      url: img2Url,
      image: {
        childImageSharp: { fluid: img2Fluid }
      }
    },
    {
      url: img3Url,
      image: {
        childImageSharp: { fluid: img3Fluid }
      }
    },
    ...otherVideos
  ] = videos;

  const [isVisible, handleChangePosition] = useScroll();

  return (
    <>
      <Waypoint bottomOffset="20%" onPositionChange={handleChangePosition} />
      <Box
        css={`
          position: relative;
          opacity: ${isVisible ? 1 : 0};
          transform: ${isVisible ? "translateY(0px)" : "translateY(70px)"};
          transition: all 0.4s ease-out;
        `}
      >
        <Flex
          flexDirection={["column", null, "row-reverse", null]}
          css={`
            position: absolute;
            top: 0px;
            left: 0px;
            @media (max-width: 1279px) {
              position: relative;
            }
          `}
        >
          <Box
            px={[4, "115px", 11, 15]}
            pt={[6, 11, 11, 15]}
            pb={[6, 11, 27, "375px"]}
            backgroundColor="white"
            css={`
              max-width: 61%;
              position: relative;
              @media (max-width: 1279px) {
                max-width: 100%;
              }
            `}
          >
            <P
              fontSize={["100px", "150px", null, null]}
              color="#eef2f4"
              fontWeight="bold"
              css={`
                line-height: 100px !important;
                position: absolute;
                top: 80px;
                right: 80px;
                @media (max-width: 1919px) {
                  top: 50px;
                  right: 50px;
                }
                @media (max-width: 767px) {
                  top: 20px;
                  left: 20px;
                  line-height: 70px !important;
                }
              `}
            >
              02
            </P>
            <Box
              width={["100%", null, "60%", null]}
              ml="auto"
              css={`
                position: relative;
                min-width: 400px !important;
                @media (max-width: 1279px) {
                  min-width: auto !important;
                }
              `}
            >
              <H3 mb={3}>{title}</H3>
              <P mb={4}>{text}</P>
              <H4 mb={2}>{whatWeDid.title}</H4>
              <Box
                as="ul"
                css={`
                  list-style-type: disc;
                  padding-left: 17px;
                `}
              >
                {whatWeDid.list.map(item => (
                  <P key={item} as="li">
                    {item}
                  </P>
                ))}
              </Box>
            </Box>
          </Box>
        </Flex>
        <BrowserView>
          <Flex
            pt={[0, null, 21, 19]}
            mb={5}
            justifyContent="space-between"
            css={`
              width: 52%;
              @media (max-width: 1279px) {
                display: none !important;
              }
            `}
          >
            <VideoLink
              onClick={() => openVideo(img1Url)}
              fluid={img1Fluid}
              width="calc(50% - 25px)"
            />
            <VideoLink
              onClick={() => openVideo(img2Url)}
              fluid={img2Fluid}
              width="calc(50% - 25px)"
            />
          </Flex>
          <VideoLink
            onClick={() => openVideo(img3Url)}
            fluid={img3Fluid}
            mr={11}
            mb={5}
            css={`
              @media (max-width: 1279px) {
                display: none !important;
              }
            `}
          />
          <Flex
            mr={11}
            css={`
              @media (max-width: 1279px) {
                display: none !important;
              }
            `}
          >
            {otherVideos.map(
              ({
                url,
                image: {
                  childImageSharp: { id, fluid }
                }
              }) => (
                <VideoLink
                  onClick={() => openVideo(url)}
                  key={id}
                  fluid={fluid}
                  css={`
                    flex-grow: 1;
                    margin-right: 50px !important;
                    :last-child {
                      margin-right: 0px !important;
                    }
                  `}
                />
              )
            )}
          </Flex>
        </BrowserView>
        <Box
          margin="auto"
          css={`
            min-width: 50%;
            transform: translateX(-40%);
            display: none !important;
            @media (max-width: 1279px) {
              display: block !important;
              min-width: calc(100% - 7%);
              max-width: calc(100% - 7%);
              transform: translateX(0);
              transform: translateY(-60px);
            }
            @media (max-width: 779px) {
              min-width: calc(100% - 12%);
              max-width: calc(100% - 12%);
              transform: translateX(0);
              transform: translateY(-30px);
            }
          `}
        >
          <Slider>
            {videos.map(
              ({
                url,
                tabletImage: {
                  childImageSharp: { id, fluid }
                }
              }) => (
                <VideoLink
                  onClick={() => openVideo(url)}
                  key={id}
                  fluid={fluid}
                />
              )
            )}
          </Slider>
        </Box>
      </Box>
    </>
  );
};

MakingVideos.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  whatWeDid: PropTypes.shape().isRequired,
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      image: PropTypes.shape().isRequired
    })
  ).isRequired,
  openVideo: PropTypes.func.isRequired
};

export default MakingVideos;
