import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Flex, Button } from "rebass";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import Frame from "../components/Frame";
import { H1, H5 } from "../components/Typography";
import SocialIcons from "../components/SocialIcons";
import Navigation from "../components/Navigation";
import Image from "../components/Image";
import SEO from "../components/SEO";
import Link from "../components/Link";
import Solution from "../components/Solution";
import MakingVideos from "../components/MakingVideos";
import Modal from "../components/Modal";
import Player from "../components/Player";
import { useModal } from "../hooks/modal";
import FindingCreatives from "../components/FindingCreatives";

const CaseStudiesPage = () => {
  const {
    site: {
      siteMetadata: { titleTemplate, siteUrl }
    },
    caseStudiesPageInfo: {
      frontmatter: { seoTitle, description, keywords, longTitle }
    },
    headerBgImage: {
      childImageSharp: { fluid: headerBgImageFluid }
    },
    solutionContent: { frontmatter: solutionContent },
    makingVideosContent: { frontmatter: makingVideosContent },
    SEOImage: {
      childImageSharp: { fluid: SEOImageFluid }
    }
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          titleTemplate
          siteUrl
        }
      }
      caseStudiesPageInfo: markdownRemark(
        frontmatter: { name: { eq: "case studies" } }
      ) {
        frontmatter {
          seoTitle
          description
          keywords
          longTitle
        }
      }
      headerBgImage: file(relativePath: { eq: "images/bg-case-study.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      solutionContent: markdownRemark(
        frontmatter: { collection_name: { eq: "solution" } }
      ) {
        frontmatter {
          title
          text
          whatWeDid {
            title
            list
          }
          images {
            childImageSharp {
              id
              original {
                width
              }
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      makingVideosContent: markdownRemark(
        frontmatter: { collection_name: { eq: "making-videos" } }
      ) {
        frontmatter {
          title
          text
          whatWeDid {
            title
            list
          }
          videos {
            url
            image {
              childImageSharp {
                id
                original {
                  width
                }
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            tabletImage {
              childImageSharp {
                id
                original {
                  width
                }
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
      SEOImage: file(relativePath: { eq: "images/main-page_1440-1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const [isOpenModal, openModal, closeModal] = useModal();
  const [videoUrl, setVideoUrl] = useState();
  const [isStartAnimation, setIsStartAnimation] = useState(false);

  useEffect(() => {
    setIsStartAnimation(true);
  }, [setIsStartAnimation]);

  return (
    <>
      <SEO
        titleTemplate={titleTemplate}
        title={seoTitle}
        keywords={keywords}
        url={siteUrl}
        description={description}
        image={`${siteUrl}${SEOImageFluid.src}`}
      />
      <Frame
        pt={[3, 5, null, null]}
        pb={[2, 6, 8, 11]}
        color="white"
        css={{
          overflow: "hidden",
          position: "relative"
        }}
      >
        <Image
          fluid={headerBgImageFluid}
          css={`
            width: 100%;
            z-index: -1;
            left: 0px;
            top: 0px;
            right: 0px;
            bottom: 0px;
            margin: auto;
            position: absolute !important;
          `}
        />
        <Navigation />
        <Flex
          justifyContent="space-between"
          height="calc(100% - 30px)"
          flexDirection={["column", "row", null, null]}
        >
          <Flex
            flexDirection="column"
            justifyContent="center"
            pt={[8, 13, 18, 23]}
          >
            <H1
              mb={4}
              css={{
                opacity: isStartAnimation ? 1 : 0,
                transform: isStartAnimation
                  ? "translateY(0px)"
                  : "translateY(100px)",
                transition: "all 0.4s ease-out",
                transitionDelay: "0.2s"
              }}
            >
              U beats
              <br />
              case study.
            </H1>
            <H5
              width={[1, 0.7, 1, null]}
              mb={40}
              css={{
                opacity: isStartAnimation ? 1 : 0,
                transform: isStartAnimation
                  ? "translateY(0px)"
                  : "translateY(100px)",
                transition: "all 0.4s ease-out",
                transitionDelay: "0.2s"
              }}
            >
              {longTitle}
            </H5>
            <Link
              href="#contactUs"
              width="max-content"
              onClick={() => {
                trackCustomEvent({
                  category: "Click",
                  action: "HitUsUp",
                  label: "CaseStudyPage"
                });
              }}
              css={{
                opacity: isStartAnimation ? 1 : 0,
                transform: isStartAnimation
                  ? "translateY(0px)"
                  : "translateY(100px)",
                transition: "all 0.4s ease-out",
                transitionDelay: "0.3s"
              }}
            >
              <Button
                variant="headerButton"
                css={`
                  @media (min-width: 1280px) {
                    :hover {
                      background-color: transparent;
                      color: white;
                    }
                  }
                `}
              >
                hit us up
              </Button>
            </Link>
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="flex-end"
            css={`
              min-width: auto !important;
            `}
          >
            <SocialIcons
              css={`
                display: flex !important;
                @media (max-width: 767px) {
                  display: none !important;
                }
              `}
              iconProps={{
                mb: [0, "25px", null, null],
                css: {
                  ":last-child": {
                    marginBottom: "0px"
                  }
                }
              }}
            />
          </Flex>
        </Flex>
      </Frame>
      <Frame pt={[10, 15, 20, 25]}>
        <Solution {...solutionContent} />
      </Frame>
      <Frame p={[7, 9, 20, 25]}>
        <MakingVideos
          {...makingVideosContent}
          openVideo={url => {
            setVideoUrl(url);
            openModal();
          }}
        />
      </Frame>
      <Frame pb={[10, 15, 20, 25]}>
        <FindingCreatives />
      </Frame>
      <Modal closeModal={closeModal} isOpenModal={isOpenModal}>
        {isOpenModal ? (
          <Player src={videoUrl} controls width="100%" height="0px" />
        ) : null}
      </Modal>
    </>
  );
};

export default CaseStudiesPage;
